import { defaultTheme, mq, styled, useLanguageDirection } from '@asicsdigital/oneasics-blocks';

const StyledPage = styled.div(
  mq({
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: ['160px', '128px'],
    paddingLeft: [null, null, '40px', '72px'],
    paddingRight: [null, null, '40px', '72px'],
    paddingTop: '24px',
  }),
);

/**
 * The container for all elements on a page.
 */
export const Page = ({ children }) => {
  const { direction } = useLanguageDirection();

  return (
    <StyledPage className={defaultTheme.theme} dir={direction} id="page">
      {children}
    </StyledPage>
  );
};
